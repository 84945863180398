import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { mapToArticles } from '~/scopes/cms/helpers/mapToArticles'

import type { ArticleCard } from '../../components/ArticleCard/ArticleCard.vue'

export function useGetRelatedArticles(
  contentRef: MaybeRefOrGetter<ArticlePageContent | null>,
) {
  return computed(() => {
    const content = toValue(contentRef)

    if (
      isEmpty(content?.relatedArticles) ||
      isEmpty(content.relatedArticles.props)
    ) {
      return []
    }

    const relatedArticles = mapToArticles(content?.relatedArticles?.props)

    return relatedArticles.map<ArticleCard>((article) => {
      const { id, image, link, text, title, translatedCategory } = article

      return {
        id: id.toString(),
        category: translatedCategory,
        imageAlt: image.alt,
        imageSrc: image.src,
        link,
        text,
        title,
      }
    })
  })
}
