import { useRoute } from '#imports'
import { computed, ref } from 'vue'

import { voteForArticle } from '@backmarket/http-api/src/api-specs-content'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import type { Vote } from '../VoteButtons.types'

export function useVoteForArticle() {
  const { openErrorToast } = useTheToast()
  const logger = useLogger()
  const route = useRoute()

  const userVote = ref<Vote>()

  const hasVoted = computed(() => !!userVote.value)

  async function postVoteForArticle(vote: Vote) {
    const articleSlug = route.params.pageName

    const previousValue = userVote.value
    userVote.value = vote
    try {
      await $httpFetch(voteForArticle, {
        pathParams: { articleSlug },
        body: { vote },
      })
    } catch (err) {
      userVote.value = previousValue
      openErrorToast()

      const error = err as Error
      logger.error('[ACQ] Error when posting a vote for an article', { error })
    }
  }

  return { postVoteForArticle, userVote, hasVoted }
}
