<template>
  <fieldset
    class="flex flex-col items-center justify-center gap-16 lg:flex-row lg:justify-start"
  >
    <legend id="voteLegendId" class="body-1-bold float-left">
      {{ i18n(translations.callToVote) }}
    </legend>
    <div class="flex justify-center gap-8">
      <RevButtonTiny
        aria-describedby="voteLegendId"
        :aria-label="yesLabel"
        data-test="up-vote-article-button"
        :disabled="hasVoted"
        :icon="upVoteIcon"
        variant="secondary"
        @click="postVoteForArticle('up')"
      >
        {{ upVoteButtonLabel }}
      </RevButtonTiny>

      <RevButtonTiny
        aria-describedby="voteLegendId"
        :aria-label="noLabel"
        data-test="down-vote-article-button"
        :disabled="hasVoted"
        :icon="downVoteIcon"
        variant="secondary"
        @click="postVoteForArticle('down')"
      >
        {{ downVoteButtonLabel }}
      </RevButtonTiny>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { IconThumbsDownFilled } from '@ds/icons/IconThumbsDownFilled'
import { IconThumbsDownOutlined } from '@ds/icons/IconThumbsDownOutlined'
import { IconThumbsUpFilled } from '@ds/icons/IconThumbsUpFilled'
import { IconThumbsUpOutlined } from '@ds/icons/IconThumbsUpOutlined'

import translations from './VoteButtons.translations'
import { useVoteForArticle } from './composables/useVoteForArticle'

const i18n = useI18n()
const { postVoteForArticle, userVote, hasVoted } = useVoteForArticle()

const props = defineProps<{
  upVotes?: number
  downVotes?: number
}>()

const hasVotes = computed(() => {
  return !!props.upVotes || !!props.downVotes
})

const upVoteIcon = computed(() => {
  return userVote.value === 'up' ? IconThumbsUpFilled : IconThumbsUpOutlined
})
const downVoteIcon = computed(() => {
  return userVote.value === 'down'
    ? IconThumbsDownFilled
    : IconThumbsDownOutlined
})

const yesLabel = computed(() => i18n(translations.articleVoteYes))
const noLabel = computed(() => i18n(translations.articleVoteNo))
const upVoteButtonLabel = computed(() =>
  hasVotes.value ? `${props.upVotes}%` : yesLabel.value,
)
const downVoteButtonLabel = computed(() =>
  hasVotes.value ? ` ${props.downVotes}%` : noLabel.value,
)
</script>
