<template>
  <div
    class="flex justify-center"
    :class="{
      'px-24 md:px-32 lg:px-0': hasPadding,
    }"
  >
    <div
      class="max-w-full grow"
      :class="{
        'md:max-w-[736px]': isNarrow,
        'md:max-w-[1120px]': isLarge,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BlockType } from '@backmarket/http-api/src/api-specs-content'

import { getDimensionProperties } from './ArticleBlockContainer.utils'

const props = defineProps<{
  blockType: BlockType
}>()

const { hasPadding, isNarrow, isLarge } = getDimensionProperties(
  props.blockType,
)
</script>
