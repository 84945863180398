import { useRouteLocationWithLocale } from '#imports'
import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content'

import { CMS } from '../../../cms/routes-names'

export function useGetBreadcrumb(
  contentRef: MaybeRefOrGetter<ArticlePageContent | null>,
) {
  return computed(() => {
    const content = toValue(contentRef)

    if (!content) {
      return {
        currentPageTitle: '',
        intermediaryPath: [],
      }
    }

    const resolveLocalizedRoute = useRouteLocationWithLocale()
    const { categories = [], titleBreadcrumb: currentPageTitle = '' } = content

    const intermediaryPath = categories.map(({ title, slug }) => {
      return {
        title,
        link: resolveLocalizedRoute({
          name: slug === 'content' ? CMS.ARTICLE_ROOT_HUB : CMS.ARTICLE_HUB,
          params: {
            unifiedName: slug,
          },
        }),
      }
    })

    return {
      currentPageTitle,
      intermediaryPath,
    }
  })
}
