<template>
  <div v-if="updateDate" class="body-1" :class="textColorClass">
    {{
      i18n(translations.articleUpdateDate, {
        date: i18n.date(updateDate, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      })
    }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { BannerTextColor } from '@backmarket/http-api/src/api-specs-content/models/banner'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { getBannerTextColorClass } from '~/scopes/cms/helpers/banner/getTextColor'

import translations from './PublicationUpdateDate.translations'

const props = defineProps<{
  updateDate?: Date
  textColor?: BannerTextColor
}>()

const i18n = useI18n()

const textColorClass = computed(() => {
  return getBannerTextColorClass(props.textColor)
})
</script>
