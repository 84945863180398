<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="flex flex-col items-center lg:flex-row">
    <div class="relative">
      <RevIllustration
        alt=""
        class="absolute bottom-[80%] right-0 z-10 inline-block h-40 w-48 lg:left-[33%]"
        :height="40"
        src="/img/articles/crown.svg"
        :width="48"
      />
      <div
        class="border-static-default-hi mood-cornflower rounded-full relative h-96 w-96 overflow-hidden border-4 lg:mr-56"
      >
        <RevIllustration
          :alt="image?.alt"
          class="absolute min-h-full w-auto object-cover"
          :height="96"
          :src="image?.src"
          :width="96"
        />
      </div>
    </div>

    <div class="mt-24 flex flex-col gap-24 text-center lg:mt-0 lg:text-left">
      <div>
        <h2 class="heading-2 flex flex-col lg:flex-row lg:flex-wrap">
          <span>
            <span>
              {{ i18n(translations.title, { name }) }}
            </span>
            <span v-if="hasRole">,&nbsp;</span>
          </span>
          <span v-if="hasRole">{{ role }}</span>
        </h2>

        <p v-if="hasPresentation" class="text-static-default-low body-1">
          {{ presentation }}
        </p>
      </div>

      <ul v-if="profiles.length > 0" class="flex items-center gap-8">
        <template v-for="profile in profilesWithIconPath">
          <RevButtonBase
            v-if="profile.iconPath"
            :key="profile.icon"
            :rel="profile.link?.rel"
            :target="profile.link?.target"
            :to="profile.link?.href"
          >
            <RevIllustration
              :alt="profile.icon"
              class="size-24"
              :height="24"
              :src="profile.iconPath"
              :width="24"
            />
          </RevButtonBase>
        </template>
      </ul>

      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type {
  Image,
  SocialProfile,
} from '@backmarket/http-api/src/api-specs-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Author.translations'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    name: string
    role?: string
    presentation?: string
    image: Image
    profiles?: SocialProfile[]
  }>(),
  {
    role: '',
    presentation: '',
    profiles: () => [],
  },
)
const getSocialIconPath = (icon: SocialProfile['icon']) => {
  switch (icon) {
    case 'facebook':
      return '/img/socials/facebook.svg'

    case 'twitter':
      return '/img/socials/twitter.svg'

    case 'instagram':
      return '/img/socials/instagram.svg'

    case 'linkedin':
      return '/img/socials/linkedin.svg'

    case 'pinterest':
      return '/img/socials/pinterest.svg'

    case 'tiktok':
      return '/img/socials/tiktok.svg'

    case 'medium':
      return '/img/socials/medium.svg'

    case 'link':
      return '/img/socials/link.svg'

    case 'profile':
      return '/img/socials/profile.svg'

    case 'youtube':
      return '/img/socials/youtube.svg'

    default:
      return ''
  }
}
const profilesWithIconPath = computed(() =>
  props.profiles.map((profile) => ({
    ...profile,
    iconPath: getSocialIconPath(profile.icon),
  })),
)

const hasPresentation = computed(() => !!props.presentation)
const hasRole = computed(() => !!props.role)
</script>
